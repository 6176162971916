import React, { useState, useRef, useEffect } from 'react'
import Img from 'gatsby-image'
import { s, colors, alpha, globals } from '../../style'
import { WrapperSection } from '../../wrappers'
import { Collapse } from '@material-ui/core'
import Avatar from '@material-ui/icons/AccountCircle'
import LinkIcon from '@material-ui/icons/Link'
import { ScrollDots } from '../../elements'
import SideLine from '../../elements/SideLine/SideLine'
import ScrollContainer from 'react-indiana-drag-scroll'

const Comment = ({ theme, data }) => {
  const [isExpanded, setExpand] = useState(true)
  return (
    <div css={[sComment.wrapper]}>
      <div
        css={[sComment, sCommentTheme(theme)]}
        onClick={() => setExpand(!isExpanded)}
        onKeyPress={() => setExpand(!isExpanded)}
        role="button"
        tabIndex={0}>
        <Collapse
          in={isExpanded}
          collapsedHeight={64}
          css={sComment.textWrapper}>
          <div css={[sHidder(theme), { opacity: isExpanded ? 0 : 1 }]} />
          <span css={[{ color: colors[theme].main, fontSize: '0.75rem' }]}>
            {data.text}
          </span>
          <div css={{ paddingBottom: '1rem' }} />
        </Collapse>
        <div css={sComment.author}>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            {data.author_image?.childImageSharp?.fluid ? (
              <Img
                alt={data.author_title}
                style={sComment.image}
                fluid={data.author_image.childImageSharp.fluid}
              />
            ) : (
              <Avatar css={sComment.avatar} />
            )}
            <div css={sComment.author_text}>
              <p className="comment-author-title">{data.author_title}</p>
              <p className="comment-author-subtitle">{data.author_subtitle}</p>
            </div>
          </div>
          {data.author_link && (
            <a
              href={data.author_link}
              css={sCommentLinkTheme(theme)}
              rel="noreferrer"
              target="_blank">
              <LinkIcon />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

const CommentsMinis = ({ data, theme }) => {
  const { comments, slug_section, title } = data
  const refComments = useRef(null)

  useEffect(() => {
    if (refComments.current)
      setTimeout(() => refComments?.current?.scrollTo({ left: 1 }), 0)
  }, [])

  return (
    <section id={slug_section} css={{ contetnVisibility: 'auto' }}>
      <WrapperSection
        outside={
          <SideLine theme={theme} extraCss={{ marginBottom: 0 }}>
            {title}
          </SideLine>
        }></WrapperSection>
      <div css={sComments}>
        <ScrollContainer
          nativeMobileScroll
          innerRef={refComments}
          css={[
            sCommentsContainer,
            // { [s.md]: { width: features.length * 460 } },
            // features.length <= 3
            //   ? { [s.md]: { justifyContent: 'center' } }
            //   : { [s.md]: { marginLeft: 100 } },
          ]}>
          {comments.map((comment, id) => (
            <Comment key={id} data={comment} theme={theme} />
          ))}
        </ScrollContainer>
      </div>
      <ScrollDots
        refElement={refComments}
        arr={comments}
        extraCss={sDots(theme)}
      />
    </section>
  )
}

// const sHeadline = {
//   [s.sm_down]: {
//     width: '100%',
//     textAlign: 'center',
//     marginBottom: '0rem',
//   },
//   [s.md]: { padding: '0 2rem', marginBottom: '2rem' },
// }

const sComments = {
  display: 'flex',
  overflowX: 'auto',
  padding: '2rem 0rem',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'stretch',
}

const sComment = {
  wrapper: {
    [s.sm_down]: { padding: `0 ${globals.spacing.inside.mobile}px` },
    [s.md]: { padding: `0 ${globals.spacing.inside.desktop / 4}px` },
  },
  cursor: 'pointer',
  transition: 'transform 0.25s ease-out, box-shadow 0.25s ease-out',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  flexShrink: 0,
  width: 366,
  height: '100%',
  borderRadius: 16,
  padding: '2rem',
  [s.xs]: {
    width: `calc(100vw - ${globals.spacing.inside.mobile * 2}px)`,
  },

  textWrapper: {
    position: 'relative',
    fontStyle: 'italic',
    [s.sm_down]: { marginBottom: globals.spacing.inside.mobile },
    [s.md]: { marginBottom: globals.spacing.inside.desktop / 4 },
  },

  image: {
    width: 40,
    height: 40,
    borderRadius: 32,
  },

  avatar: {
    width: 40,
    height: 40,
    opacity: 0.3,
  },

  author: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  author_text: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0.75rem',
    '.comment-author-title': {
      opacity: 0.6,
      fontWeight: 700,
      fontSize: '0.875rem',
    },
    '.comment-author-subtitle': {
      opacity: 0.6,
      fontSize: '0.75rem',
      fontWeight: 400,
    },
  },
}
const sCommentsContainer = {
  display: 'flex',
  overflowX: 'auto',
  [s.md]: { padding: '0rem 2rem 4rem' },
  [s.sm_down]: { padding: '0rem 0rem 0rem' },
  // overflowY: 'hidden',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'stretch',
}
const sCommentTheme = (theme) => ({
  backgroundColor: colors[theme].container,
  boxShadow: `0px 8px 16px ${colors[theme].mainDark.concat(alpha[4])}`,
  '&:hover': {
    [s.hover]: {
      transform: 'translateY(16px)',
      boxShadow: `0px 8px 32px ${colors[theme].mainDark.concat(alpha[16])}`,
    },
  },
})

const sHidder = (theme) => ({
  transition: 'opacity 0.2s linear',
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: `linear-gradient(${colors[theme].container.concat(
    alpha[0]
  )}, ${colors[theme].container.concat(alpha[100])} 95%)`,
})

const sCommentLinkTheme = (theme) => ({
  transition: 'background-color 0.25s ease-out, color 0.25s ease-out',
  width: 40,
  height: 40,
  borderRadius: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
  color: colors[theme].text.concat(alpha[30]),

  '&:hover': {
    backgroundColor: colors[theme].main.concat(alpha[16]),
    color: colors[theme].main,
  },
})

const sDots = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0 2rem',
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: colors[theme].main,
  },
  inactive: {
    cursor: 'pointer',
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0.16,
    padding: `0 6px`,
    '&:hover': {
      [s.hover]: {
        opacity: 0.6,
      },
    },
  },
  active: {
    opacity: 1,
    '&:hover': {
      [s.hover]: {
        opacity: 1,
      },
    },
  },
})

export default CommentsMinis
